import validate from "jquery-validation";

$(document).ready(function(){
 // want_tour !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $("#want_tour").validate({
        errorElement: 'span',
        errorClass: 'span__error',
        ignoreTitle: true,
        errorPlacement: function(error, element) {
            error.appendTo($(element).parents('.input-block'));
        },
        highlight: function(element) {
            $(element).parents('.input-wrapper').addClass("forms__error");
        },
        unhighlight: function(element) {
            $(element).parents('.input-wrapper').removeClass("forms__error");
        },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $("#want_tour").serialize();
            $("#want_tour")[0].reset();
            $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                    "subj": "callback"
                },
                success: function(data) {
                    $("#want_tour_success").slideDown();
                    setTimeout(function() {
                        $("#want_tour_success").slideUp(300)
                    }, 2000)
                }
            })
        }
    });
    $('#want_tour_do').click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        $("#want_tour").submit();
    });
 // want_tour !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 // fixed-modal-callback !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 $("#form_fixed_callback").validate({
     errorElement: 'span',
     errorClass: 'span__error',
     ignoreTitle: true,
     errorPlacement: function(error, element) {
         error.appendTo($(element).parents('.input-block'));
     },
     highlight: function(element) {
         $(element).parents('.input-wrapper').addClass("forms__error");
     },
     unhighlight: function(element) {
         $(element).parents('.input-wrapper').removeClass("forms__error");
     },
     submitHandler: function(form) {
         var csrf_token = $('meta[name="csrf-token"]').attr('content');
         var formdata = $("#form_fixed_callback").serialize();
         $("#form_fixed_callback")[0].reset();
         $.ajax({
             url: routes.postSend,
             type: 'POST',
             data: {
                 "_token" : csrf_token,
                 "data": formdata,
                 "subj": "callback_1"
             },
             success: function(data) {
                 $("#success_fixed_callback").slideDown();
                 setTimeout(function() {
                     $('.modals').removeClass('active_js');
                     $('body').removeClass('overflow');
                     $("#success_fixed_callback").slideUp(300);
                 }, 4000)
             }
         })
     }
 });
 $('#do_fixed_callback').click(function(e) {
     e.stopPropagation();
     e.preventDefault();
     $("#form_fixed_callback").submit();
 });
 // fixed-modal-callback !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


 // MAIN_want_tour !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $("#main_want_tour").validate({
        errorElement: 'span',
        errorClass: 'span__error',
        ignoreTitle: true,
        errorPlacement: function(error, element) {
            error.appendTo($(element).parents('.input-block'));
        },
        highlight: function(element) {
            $(element).parents('.input-wrapper').addClass("forms__error");
        },
        unhighlight: function(element) {
            $(element).parents('.input-wrapper').removeClass("forms__error");
        },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $("#main_want_tour").serialize();
            $("#main_want_tour")[0].reset();
            $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                    "subj": "callback"
                },
                success: function(data) {
                    $("#main_want_tour_success").slideDown();
                    setTimeout(function() {
                        $("#main_want_tour_success").slideUp(300)
                    }, 2000)
                }
            })
        }
    });
    $('#do_main_want_tour').click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        $("#main_want_tour").submit();
    });
 // MAIN_want_tour !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// modal_slider_form !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
$("#modal_slider_form").validate({
    errorElement: 'span',
    errorClass: 'span__error',
    ignoreTitle: true,
    errorPlacement: function(error, element) {
        error.appendTo($(element).parents('.input-block'));
    },
    highlight: function(element) {
        $(element).parents('.input-wrapper').addClass("forms__error");
    },
    unhighlight: function(element) {
        $(element).parents('.input-wrapper').removeClass("forms__error");
    },
    submitHandler: function(form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $("#modal_slider_form").serialize();
        $("#modal_slider_form")[0].reset();
        $.ajax({
            url: routes.postSend,
            type: 'POST',
            data: {
                "_token" : csrf_token,
                "data": formdata,
                "subj": "cruiseorder"
            },
            success: function(data) {
                $("#success_modal_slider_form").slideDown();
                setTimeout(function() {
                    $('.modals').removeClass('active_js');
                    $('body').removeClass('overflow');
                    $("#success_modal_slider_form").slideUp(300);
                }, 4000)
            }
        })
    }
});
$('#do_modal_slider_form').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $("#modal_slider_form").submit();
});
// modal_slider_form !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// modal_booking_form !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
$("#booking-tours_form").validate({
    errorElement: 'span',
    errorClass: 'span__error',
    ignoreTitle: true,
    errorPlacement: function(error, element) {
        error.appendTo($(element).parents('.input-block'));
    },
    highlight: function(element) {
        $(element).parents('.input-wrapper').addClass("forms__error");
    },
    unhighlight: function(element) {
        $(element).parents('.input-wrapper').removeClass("forms__error");
    },
    submitHandler: function(form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $("#booking-tours_form").serialize();
        $("#booking-tours_form")[0].reset();
        $.ajax({
            url: routes.postSend,
            type: 'POST',
            data: {
                "_token" : csrf_token,
                "data": formdata,
                "subj": "reservation"
            },
            success: function(data) {
                $("#success_booking-tours_form").slideDown();
                setTimeout(function() {
                    $('.modals').removeClass('active_js');
                    $('body').removeClass('overflow');
                    $("#success_booking-tours_form").slideUp(300);
                }, 4000)
            }
        })
    }
});
$('#do_booking-tours_form').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $("#booking-tours_form").submit();
});
// modal_booking_form !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// callback_help form !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $("#callback_help").validate({
        errorElement: 'span',
        errorClass: 'span__error',
        ignoreTitle: true,
        errorPlacement: function(error, element) {
            error.appendTo($(element).parents('.input-block'));
        },
        highlight: function(element) {
            $(element).parents('.input-wrapper').addClass("forms__error");
        },
        unhighlight: function(element) {
            $(element).parents('.input-wrapper').removeClass("forms__error");
        },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $("#callback_help").serialize();
            $("#callback_help")[0].reset();
            $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                    "subj": "findflights"
                },
                success: function(data) {
                    $("#success_callback_help").slideDown();
                    setTimeout(function() {
                        $("#success_callback_help").slideUp(300)
                    }, 2000)
                }
            })
        }
    });
    $('#do_callback_help').click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        $("#callback_help").submit();
    });
// callback_help form !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Feedback !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $("#feedback_form").validate({
        errorElement: 'span',
        errorClass: 'span__error',
        ignoreTitle: true,
      errorPlacement: function(error, element) {
          error.appendTo($(element).parents('.input-block'));
      },
      highlight: function(element) {
          $(element).parents('.input-wrapper').addClass("forms__error");
      },
      unhighlight: function(element) {
          $(element).parents('.input-wrapper').removeClass("forms__error");
      },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $("#feedback_form").serialize();
            $("#feedback_form")[0].reset();
            $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                    "subj": "feedback"
                },
                success: function(data) {
                    $("#feedback_success").slideDown();
                        setTimeout(function() {
                            $("#feedback_success").slideUp(300)
                        }, 2000)
                }
            })
        }
    });
    $('#feedback_do').click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        $("#feedback_form").submit();
    });
//  Feedback !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// modal_booking_form !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
$("#modal_cruise").validate({
    errorElement: 'span',
    errorClass: 'span__error',
    ignoreTitle: true,
    errorPlacement: function(error, element) {
        error.appendTo($(element).parents('.input-block'));
    },
    highlight: function(element) {
        $(element).parents('.input-wrapper').addClass("forms__error");
    },
    unhighlight: function(element) {
        $(element).parents('.input-wrapper').removeClass("forms__error");
    },
    submitHandler: function(form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $("#modal_cruise").serialize();
        $("#modal_cruise")[0].reset();
        $.ajax({
            url: routes.postSend,
            type: 'POST',
            data: {
                "_token" : csrf_token,
                "data": formdata,
                "subj": "cruiseorder"
            },
            success: function(data) {
                $("#success_modal_cruise").slideDown();
                setTimeout(function() {
                    $('.modals').removeClass('active_js');
                    $('body').removeClass('overflow');
                    $("#success_modal_cruise").slideUp(300);
                }, 4000)
            }
        })
    }
});
$('#do_modal_cruise').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $("#modal_cruise").submit();
});
// modal_booking_form !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// landing_callback_top !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
$("#landing_callback_top").validate({
    errorElement: 'span',
    errorClass: 'span__error',
    ignoreTitle: true,
    errorPlacement: function(error, element) {
        error.appendTo($(element).parents('.input-block'));
    },
    highlight: function(element) {
        $(element).parents('.input-wrapper').addClass("forms__error");
    },
    unhighlight: function(element) {
        $(element).parents('.input-wrapper').removeClass("forms__error");
    },
    submitHandler: function(form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $("#landing_callback_top").serialize();
        $("#landing_callback_top")[0].reset();
        $.ajax({
            url: routes.postSend,
            type: 'POST',
            data: {
                "_token" : csrf_token,
                "data": formdata,
                "subj": "landingforms"
            },
            success: function(data) {
                $("#success_landing_callback_top").slideDown();
                setTimeout(function() {
                    $("#success_landing_callback_top").slideUp(300)
                }, 2000)
            }
        })
    }
});
$('#do_landing_callback_top').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $("#landing_callback_top").submit();
});
//  landing_callback_top!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// landing_callback_two !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
$("#landing_callback_two").validate({
    errorElement: 'span',
    errorClass: 'span__error',
    ignoreTitle: true,
    errorPlacement: function(error, element) {
        error.appendTo($(element).parents('.input-block'));
    },
    highlight: function(element) {
        $(element).parents('.input-wrapper').addClass("forms__error");
    },
    unhighlight: function(element) {
        $(element).parents('.input-wrapper').removeClass("forms__error");
    },
    submitHandler: function(form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $("#landing_callback_two").serialize();
        $("#landing_callback_two")[0].reset();
        $.ajax({
            url: routes.postSend,
            type: 'POST',
            data: {
                "_token" : csrf_token,
                "data": formdata,
                "subj": "landingforms"
            },
            success: function(data) {
                $("#landing_callback_two_success").slideDown();
                setTimeout(function() {
                    $("#landing_callback_two_success").slideUp(300)
                }, 2000)
            }
        })
    }
});
$('#do_landing_callback_two').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $("#landing_callback_two").submit();
});
//  landing_callback_two!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// modal_pick_tour !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
$("#modal_pick_tour").validate({
    errorElement: 'span',
    errorClass: 'span__error',
    ignoreTitle: true,
    errorPlacement: function(error, element) {
        error.appendTo($(element).parents('.input-block'));
    },
    highlight: function(element) {
        $(element).parents('.input-wrapper').addClass("forms__error");
    },
    unhighlight: function(element) {
        $(element).parents('.input-wrapper').removeClass("forms__error");
    },
    submitHandler: function(form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $("#modal_pick_tour").serialize();
        $("#modal_pick_tour")[0].reset();
        $.ajax({
            url: routes.postSend,
            type: 'POST',
            data: {
                "_token" : csrf_token,
                "data": formdata,
                "subj": "landingforms"
            },
            success: function(data) {
                $("#modal_pick_tour_success").slideDown();
                setTimeout(function() {
                    $('.modals').removeClass('active_js');
                    $('body').removeClass('overflow');
                    $("#modal_pick_tour_success").slideUp(300);
                }, 4000)
            }
        })
    }
});
$('#do_modal_pick_tour').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $("#modal_pick_tour").submit();
});
// modal_pick_tour !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// meeting_form !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
$("#meeting_form").validate({
    errorElement: 'span',
    errorClass: 'span__error',
    ignoreTitle: true,
    errorPlacement: function(error, element) {
        error.appendTo($(element).parents('.input-block'));
    },
    highlight: function(element) {
        $(element).parents('.input-wrapper').addClass("forms__error");
    },
    unhighlight: function(element) {
        $(element).parents('.input-wrapper').removeClass("forms__error");
    },
    submitHandler: function(form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $("#meeting_form").serialize();
        $("#meeting_form")[0].reset();
        $.ajax({
            url: routes.postSend,
            type: 'POST',
            data: {
                "_token" : csrf_token,
                "data": formdata,
                "subj": "landingforms"
            },
            success: function(data) {
                $("#success_meeting_form").slideDown();
                setTimeout(function() {
                    $('.modals').removeClass('active_js');
                    $('body').removeClass('overflow');
                    $("#success_meeting_form").slideUp(300);
                }, 4000)
            }
        })
    }
});
$('#do_meeting_form').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $("#meeting_form").submit();
});
// meeting_form !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// transfer_form !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
   $("#transfer_form").validate({
       errorElement: 'span',
       errorClass: 'span__error',
       ignoreTitle: true,
       errorPlacement: function(error, element) {
           error.appendTo($(element).parents('.input-block'));
       },
       highlight: function(element) {
           $(element).parents('.input-wrapper').addClass("forms__error");
       },
       unhighlight: function(element) {
           $(element).parents('.input-wrapper').removeClass("forms__error");
       },
       submitHandler: function(form) {
           var csrf_token = $('meta[name="csrf-token"]').attr('content');
           var formdata = $("#transfer_form").serialize();
           $("#transfer_form")[0].reset();
           $.ajax({
               url: routes.postSend,
               type: 'POST',
               data: {
                   "_token" : csrf_token,
                   "data": formdata,
                   "subj": "transfer"
               },
               success: function(data) {
                   $("#transfer_form_success").slideDown();
                   setTimeout(function() {
                       $("#transfer_form_success").slideUp(300)
                   }, 2000)
               }
           })
       }
   });
   $('#do_transfer_form').click(function(e) {
       e.stopPropagation();
       e.preventDefault();
       $("#transfer_form").submit();
   });
// transfer_form !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
});
