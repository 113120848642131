import slick from "slick-carousel";
import mask from "jquery.maskedinput/src/jquery.maskedinput.js";
import select from "selectric";
import datetimepicker from "jquery-datetimepicker";
import lazyload from "jquery-lazyload";
jQuery.datetimepicker.setLocale('ru');


// Фиксированный хедер мобильная версия !!!!
    var tempScrollTop, currentScrollTop = 0;
window.onscroll = function(){
    if ($(this).scrollTop() > 350){
        $('#up_button').addClass('js_visible');
    }
    else{
        $('#up_button').removeClass('js_visible');
    }
    if ($('js_header')) {
            var windowTop = $(this).scrollTop(),
                windowH = $(window).height(),
                header = $('.js_header'),
                headerH = header.outerHeight();
            if (windowTop >= headerH) {
                $('.js_header:not(.is_active)').removeAttr('style');
                $('.js_header:not(.is_active)').removeClass('up__top');
            }
            if (tempScrollTop < windowTop ) {
                if (windowTop >= headerH) {
                    $('.js_header:not(.is_active)').addClass('up__top')
                    $('.js_header.up__top:not(.is_active)').css({
                        'top': -headerH
                    })
                } else {
                    $('.js_header:not(.is_active)').removeAttr('style');
                    $('.js_header:not(.is_active)').removeClass('up__top');
                }
            }
            else if (tempScrollTop > windowTop ) {
                $('.js_header:not(.is_active)').removeClass('up__top')
            }
            tempScrollTop = windowTop;
    }
};

window.onload = function () {
    if ($(this).scrollTop() > 350){
        $('#up_button').addClass('js_visible');
    }
    else{
        $('#up_button').removeClass('js_visible');
    }
    if ($('js_header')) {
            var windowTop = $(this).scrollTop(),
                windowH = $(window).height(),
                header = $('.js_header'),
                headerH = header.outerHeight();
                $('.main-wrap').css({
                    'margin-top': headerH
                });
            if (windowTop >= headerH) {
                $('.js_header:not(.is_active)').removeAttr('style');
                $('.js_header:not(.is_active)').removeClass('up__top');
            }
            if (tempScrollTop < windowTop ) {
                if (windowTop >= headerH) {
                    $('.js_header:not(.is_active)').addClass('up__top')
                    $('.js_header.up__top:not(.is_active)').css({
                        'top': -headerH
                    });
                } else {
                    $('.js_header:not(.is_active)').removeAttr('style');
                    $('.js_header:not(.is_active)').removeClass('up__top');
                }
            }
            else if (tempScrollTop > windowTop ) {
                $('.js_header:not(.is_active)').removeClass('up__top')
            }
            tempScrollTop = windowTop;
    }
};



$(document).ready(function(){
    $("img.lazy").lazyload();
// Кнопка наверх !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $("#up_button").on("click", function(e) {
           e.stopPropagation();
           e.preventDefault();
           $('html, body').animate({
               scrollTop: 0
           }, 500)
     });
// input_hidden_checkbox !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 if ($('#checkbox-transfer').is(':checked')){
     $('#input_hidden_checkbox').val($("#checkbox-transfer").is(':checked') ? '1' : '0');
 }

$('#checkbox-transfer').change(function () {
    $('#input_hidden_checkbox').val($(this).is(':checked') ? '1' : '0');
});
//  datepicker !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $('.datepicker').datetimepicker({
        timepicker:false,
        format:'d.m.Y'
});
//  Select !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $('.selectpicker-select').selectric({
           disableOnMobile: false,
           nativeOnMobile: false
      });
// Breadcrumbs !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $(".js-lastcrumb").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
	});
// Lang in Header !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    if($('.link-lang').hasClass('active')) {
        $('.change_lang_span.active').clone().appendTo(".active-lang");
    }
    $('.js_lang').click(function () {
        $('.js_language-block').slideToggle().toggleClass("js_active");
    });
// partners-slide !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $('.partners-slider').slick({
        slidesToShow: 6,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite:true,
        speed: 1000,
        arrows: true,
        draggable: true,
        lazyLoad: 'progressive',
        prevArrow: '<div class="partners-slider-prev slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.2 477.2"><path d="M145.2 238.6l215.5-215.5c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1 0l-225.1 225.1c-5.3 5.3-5.3 13.8 0 19.1l225.1 225c2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4c5.3-5.3 5.3-13.8 0-19.1L145.2 238.6z"/></svg></div>',
        nextArrow: '<div class="partners-slider-next slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129"><path d="m40.4 121.3c-0.8 0.8-1.8 1.2-2.9 1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8 0l53.9 53.9c1.6 1.6 1.6 4.2 0 5.8l-53.9 53.9z"/></svg></div>',
        responsive: [
            {
              breakpoint: 1201,
              settings: {
                slidesToShow: 5,

              }
          },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,

              }
          },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,

                }
            },
            {
              breakpoint: 601,
              settings: {
                slidesToShow: 2,

              }
          },
          ]

    });
// tours-slide !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $('.tours_slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        infinite:true,
        speed: 1000,
        arrows: true,
        draggable: true,
        lazyLoad: 'progressive',
        prevArrow: '<div class="tours-slider-prev slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.2 477.2"><path d="M145.2 238.6l215.5-215.5c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1 0l-225.1 225.1c-5.3 5.3-5.3 13.8 0 19.1l225.1 225c2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4c5.3-5.3 5.3-13.8 0-19.1L145.2 238.6z"/></svg></div>',
        nextArrow: '<div class="tours-slider-next slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129"><path d="m40.4 121.3c-0.8 0.8-1.8 1.2-2.9 1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8 0l53.9 53.9c1.6 1.6 1.6 4.2 0 5.8l-53.9 53.9z"/></svg></div>',
    });
//footer-slider !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
$('.footer-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite:true,
    speed: 1000,
    arrows: false,
    draggable: true,
    vertical: true,
});
// Слайдер на странице tours-page !!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $('.slider-tours-page').slick({
        dots: false,
		arrows: true,
		slidesToShow: 1,
		slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
		infinite: true,
		swipe: false,
		fade: true,
        cssEase: 'linear',
        asNavFor: '.tours-slider-nav',
        prevArrow: '<div class="tours-page-slide-prev slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.2 477.2"><path d="M145.2 238.6l215.5-215.5c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1 0l-225.1 225.1c-5.3 5.3-5.3 13.8 0 19.1l225.1 225c2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4c5.3-5.3 5.3-13.8 0-19.1L145.2 238.6z"/></svg></div>',
        nextArrow: '<div class="tours-page-slide-next slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129"><path d="m40.4 121.3c-0.8 0.8-1.8 1.2-2.9 1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8 0l53.9 53.9c1.6 1.6 1.6 4.2 0 5.8l-53.9 53.9z"/></svg></div>',
    });
    $('.tours-slider-nav').slick({
        asNavFor: '.slider-tours-page',
        dots: false,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true,
        focusOnSelect: true,
        responsive: [
            {
              breakpoint: 601,
              settings: {
                slidesToShow: 3,
                infinite: true,
                dots: false,
                centerMode: true,
              }
            }
          ]
    });
// Слайдер на странице tours-page !!!!!!!!!!!!!!!!!!!!!!!!!!!!
// gallery-block-slick !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $('.gallery-block-slick').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: false,
        infinite:true,
        speed: 1000,
        arrows: true,
        draggable: true,
        lazyLoad: 'ondemand',
        prevArrow: '<div class="gallery-block-slick-prev slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.2 477.2"><path d="M145.2 238.6l215.5-215.5c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1 0l-225.1 225.1c-5.3 5.3-5.3 13.8 0 19.1l225.1 225c2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4c5.3-5.3 5.3-13.8 0-19.1L145.2 238.6z"/></svg></div>',
        nextArrow: '<div class="gallery-block-slick-next slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129"><path d="m40.4 121.3c-0.8 0.8-1.8 1.2-2.9 1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8 0l53.9 53.9c1.6 1.6 1.6 4.2 0 5.8l-53.9 53.9z"/></svg></div>',
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 601,
              settings: {
                slidesToShow: 1,
              }
            },
          ]
    });
// Форма плейсхолдер !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
$(".phone").mask("+38(999) 999-9999");
$(".form-input")
.on("focus", function(){
    $(this).next().css("left","0px");
    $(this).next().css("top","-22px");
})
.on("blur", function(){
    if ($(this).val() == "") {
        $(this).next().css("left","20px");
        $(this).next().css("top","15px");
    }
});
// Форма плейсхолдер !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Проверка инпута на число !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
var input = document.getElementById('number_people');
if (input) {
    input.onkeypress = function (event) {
        if (event.keyCode <48 || event.keyCode >57) {
            return false;
        }
    }
};

$("#number_people").mask("999", {
    autoclear: false,
});

// Проверка инпута на число !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Modal !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $(document).on('click', '.js_modal-opener', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $('.modal').removeClass('active_js');
        var btn_modal = $(this).attr('data-modal');
        $('#'+btn_modal).addClass('active_js');
        $('body').addClass('overflow');
        if ($(this).hasClass('btn_booking')) {
            var hotel = $(this).attr('data-hotel');
            var alias = $(this).attr('data-alias');
            var unitId = $(this).attr('data-unit_id');
            var url = $(this).attr('data-url');
            $("#hotel").val(hotel);
            $('#page_alias').val(alias);
            $("#url_booking").val(url);
            $('.js_hotel').text(hotel);
            $('#unit_id').val(unitId);
        }
        if ($(this).hasClass('slider_btn')) {
            var sliderName = $(this).attr('data-name-slider');
            console.log(sliderName);
            $('#slider_name').val(sliderName);
            $('.js_slider_name').text(sliderName);
        }
        if($(this).hasClass('btn-cruise')) {
            var cruiseName = $(this).attr('data-cruise');
            var unitIdCruise = $(this).attr('data-unit_id');
            $('.js_cruise_name').text(cruiseName);
            $('#unit_id_cruise').val(unitIdCruise);
            $('#cruise_name').val(cruiseName);
        }
        if ($(this).hasClass('btn_pick_tour')) {
            var actualTour = $(this).attr('data-title-modal');
            var unitIdActTour = $(this).attr('data-unit_id');
            var hotelActual = $(this).attr('data-actual-hotel');
            $('.js_actual_name').text(actualTour);
            $('#data_title_modal').val(actualTour);
            $('#unit_id_actual').val(unitIdActTour);
            $('#data_name_modal').val(hotelActual);
        }
        if ($(this).hasClass('js_meeting')) {
            var meet = $(this).attr('data-meeting');
            var meetId = $(this).attr('data-cat_id');
            $('#meeting').val(meet);
            $('.js_title_meet').text(meet);
            $('#meeting_id').val(meetId);
        }
    });
    $(document).on('click', '.js_close_modal, .modal_overlay', function (e) {
        e.stopPropagation();
        e.preventDefault();
        var btn_modal = $(this).attr('data-close');
        $('#'+btn_modal).removeClass('active_js');
        $('body').removeClass('overflow');
    });
// Modal !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Бургер-меню !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    $('.js_menu_btn_mobile').click(function () {
        $('.js_menu_btn_mobile').toggleClass('js_active');
        $('.js_mobile_menu').toggleClass('js_active');
        $('.site').toggleClass('js_active');
        $('body').toggleClass('overflow');

    });
    $(document).on('click', '.js_mobile_menu', function(e) {
        if ($(e.target).is('.js_mobile_menu')) {
            $('.js_mobile_menu').removeClass('js_active');
            $('.js_menu_btn_mobile').removeClass('js_active');
            $('.site').removeClass('js_active');
            $('body').removeClass('overflow');
        }
    });

    $(document).on('click', '.js_close_menu', function(e) {
        e.stopPropagation();
        e.preventDefault();
        if($('.js_mobile_menu').hasClass('js_active')) {
            $('.js_mobile_menu').removeClass('js_active');
            $('.js_menu_btn_mobile').removeClass('js_active');
            $('.site').removeClass('js_active');
            $('body').removeClass('overflow');
        }
    });
// Бургер-меню !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


    // const target = document.querySelector(".target");
    //   const links = document.querySelectorAll(".mynav a");
    //
    //   function mouseenterFunc() {
    //     if (!this.parentNode.classList.contains("active")) {
    //       for (let i = 0; i < links.length; i++) {
    //         if (links[i].parentNode.classList.contains("active")) {
    //           links[i].parentNode.classList.remove("active");
    //         }
    //         links[i].style.opacity = "0.25";
    //       }
    //
    //       this.parentNode.classList.add("active");
    //       this.style.opacity = "1";
    //
    //       const width = this.getBoundingClientRect().width;
    //       const height = this.getBoundingClientRect().height;
    //       const left = this.getBoundingClientRect().left + window.pageXOffset;
    //       const top = this.getBoundingClientRect().top + window.pageYOffset;
    //
    //       target.style.width = `${width}px`;
    //       target.style.height = `${height}px`;
    //       target.style.left = `${left}px`;
    //       target.style.top = `${top}px`;
    //       target.style.borderColor = `#ff0000`;
    //       target.style.transform = "none";
    //     }
    //   }
    //
    //   for (let i = 0; i < links.length; i++) {
    //     links[i].addEventListener("click", (e) => e.preventDefault());
    //     links[i].addEventListener("mouseenter", mouseenterFunc);
    //   }
    //
    //   function resizeFunc() {
    //     const active = document.querySelector(".mynav li.active");
    //
    //     if (active) {
    //       const left = active.getBoundingClientRect().left + window.pageXOffset;
    //       const top = active.getBoundingClientRect().top + window.pageYOffset;
    //
    //       target.style.left = `${left}px`;
    //       target.style.top = `${top}px`;
    //     }
    //   }
    //
    //   window.addEventListener("resize", resizeFunc);
});
