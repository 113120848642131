
import lightcase from "lightcase";


$(document).ready(function($) {
	$('a[data-rel^=lightcase]').lightcase({
	   showTitle: true,
	   swipe: true,
	   transition: 'scrollHorizontal',
	   showSequenceInfo: false,
	   showCaption: true,
	   shrinkFactor: 0.9,
	   navigateEndless: true,
	   height: 'auto',
	   maxWidth: 1200,
	   maxHeight: 800,
	   fullScreenModeForMobile: false,
	});
});
